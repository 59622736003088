/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme } from '@mui/material';
import Constants from './constants';

export const themeGenerator = (mode: 'light' | 'dark' = 'light') => {
  const primaryTextColor = mode === 'light' ? Constants.theme.text.primary : '#fff';
  const secondaryTextColor = mode === 'light' ? Constants.theme.text.secondary : 'rgba(255, 255, 255, 0.7)';

  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 3200,
      },
    },
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff',
      },
      background: {
        default: mode === 'light' ? '#eee' : '#121212',
      },
      primary: {
        contrastText: '#fff',
        dark: '#00001f',
        main: Constants.hue.blue[900], // union blue
        light: '#344a71',
      },
      secondary: {
        contrastText: '#fff',
        dark: '#b6170c',
        main: Constants.hue.orange[500],
        light: '#ff8563',
      },
      warning: {
        contrastText: '#000',
        dark: '#f57c00',
        main: '#ff9800',
        light: '#ffb74d',
      },
      text: {
        primary: primaryTextColor,
        secondary: secondaryTextColor,
        disabled: mode === 'light' ? 'rgba(0, 0, 0, 0.5)' : `${Constants.hue.blue[50]}80`, // 255 * 0.5 = 128; 128 is 80 in hex
      },
    },
    typography: {
      h1: {
        color: primaryTextColor,
        fontWeight: 400,
        fontSize: '3rem',
        letterSpacing: '-0.24px',
        lineHeight: '40px',
      },
      h2: {
        color: primaryTextColor,
        fontWeight: 400,
        fontSize: '2.25rem',
        letterSpacing: '-0.24px',
        lineHeight: '32px',
      },
      h3: {
        color: primaryTextColor,
        fontWeight: 400,
        fontSize: '1.75rem',
        letterSpacing: '-0.06px',
        lineHeight: '28px',
      },
      h4: {
        color: primaryTextColor,
        fontWeight: 700,
        fontSize: '1.25rem',
        letterSpacing: '-0.06px',
        lineHeight: '24px',
      },
      h5: {
        color: primaryTextColor,
        fontWeight: 400,
        fontSize: '1rem',
        letterSpacing: '-0.05px',
        lineHeight: '20px',
      },
      h6: {
        color: primaryTextColor,
        fontWeight: 500,
        fontSize: '1rem',
        letterSpacing: '-0.05px',
        lineHeight: '20px',
      },
      subtitle1: {
        color: primaryTextColor,
        fontWeight: 500,
        fontSize: '1rem',
        letterSpacing: '-0.05px',
        lineHeight: '19px',
      },
      subtitle2: {
        color: primaryTextColor,
        fontWeight: 300,
        fontSize: '0.75rem',
        letterSpacing: '-0.04px',
        lineHeight: '14px',
      },
      body1: {
        color: primaryTextColor,
        fontWeight: 400,
        fontSize: '1rem',
        letterSpacing: '-0.05px',
        lineHeight: '21px',
      },
      body2: {
        color: primaryTextColor,
        fontWeight: 400,
        fontSize: '0.875rem',
        letterSpacing: '-0.04px',
        lineHeight: '18px',
      },
      button: {
        color: primaryTextColor,
        fontSize: '0.875rem',
      },
      caption: {
        fontSize: '0.875rem',
        letterSpacing: '0.33px',
        lineHeight: '13px',
      },
      overline: {
        color: secondaryTextColor,
        fontSize: '0.75rem',
        fontWeight: 400,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        textTransform: 'none',
      },
    },
  });
};

export const theme = themeGenerator('light');

export const darkTheme = themeGenerator('dark');
