import { Box, Typography } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useSetQuestion } from '../../hooks/useSetQuestion';
import { selectEnabledQuestions, selectFieldSize, selectLaborRate, selectQuestions } from '../../state';
import { Highlight } from './Highlight';
import { Paragraph } from './Paragraph';

export const Absences = () => {
  const setQuestion = useSetQuestion();

  const [annualSavings, setAnnualSavings] = useState(0);

  const enabledQuestions = useRecoilValue(selectEnabledQuestions);
  const questions = useRecoilValue(selectQuestions);
  const question = questions.find((q) => q.id === 'Absences');
  const laborRate = useRecoilValue(selectLaborRate);
  const fieldSize = useRecoilValue(selectFieldSize);

  useLayoutEffect(() => {
    const annualSavings = laborRate * fieldSize * 12;
    setAnnualSavings(annualSavings);
    if (question) {
      setQuestion({
        ...question,
        annualSavings: annualSavings,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldSize, laborRate]);

  const isVisible = Boolean(laborRate && fieldSize);
  const showTitle = enabledQuestions.length > 1;
  const monthlySavings = Math.round(annualSavings / 12);

  return (
    <>
      {isVisible ? (
        <Box sx={{ marginBottom: '24px' }}>
          {showTitle && (
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              {question?.label}
            </Typography>
          )}
          <Paragraph>
            If RIVET can save you even just one hour per month for each of your workers, that's{' '}
            <Highlight>${monthlySavings.toLocaleString()}</Highlight> per month or{' '}
            <Highlight>${Math.round(annualSavings).toLocaleString()}</Highlight> per year.
          </Paragraph>
        </Box>
      ) : (
        <Typography variant='body2'>
          <strong>{question?.label}</strong> requires values for{' '}
          <ul>
            {laborRate ? null : <li>Labor Rate</li>}
            {fieldSize ? null : <li>Field Size</li>}
          </ul>
        </Typography>
      )}
    </>
  );
};
