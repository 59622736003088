import { Box, Typography } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useSetQuestion } from '../../hooks/useSetQuestion';
import {
  selectEnabledQuestions,
  selectFieldSize,
  selectLaborRate,
  selectPercentageUnproductiveWorkforce,
  selectQuestions,
} from '../../state';
import { Highlight } from './Highlight';
import { Paragraph } from './Paragraph';

export const FieldCommunication = () => {
  const setQuestion = useSetQuestion();

  const [annualSavings, setAnnualSavings] = useState(0);

  const enabledQuestions = useRecoilValue(selectEnabledQuestions);
  const questions = useRecoilValue(selectQuestions);
  const question = questions.find((q) => q.id === 'FieldCommunication');
  const laborRate = useRecoilValue(selectLaborRate);
  const fieldSize = useRecoilValue(selectFieldSize);
  const percentageUnproductiveWorkforce = useRecoilValue(selectPercentageUnproductiveWorkforce);

  const isVisible = Boolean(laborRate && fieldSize && percentageUnproductiveWorkforce);
  const showTitle = enabledQuestions.length > 1;
  const unproductiveFieldSize = fieldSize * (percentageUnproductiveWorkforce / 100);
  const weeklyLoss = unproductiveFieldSize * laborRate * 40;
  const weeklyHoursSaved = 1;

  useLayoutEffect(() => {
    const annualSavings = weeklyHoursSaved * laborRate * unproductiveFieldSize * 52;
    setAnnualSavings(annualSavings);
    if (question) {
      setQuestion({
        ...question,
        annualSavings: annualSavings,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laborRate, unproductiveFieldSize]);

  return (
    <>
      {isVisible ? (
        <Box sx={{ marginBottom: '24px' }}>
          {showTitle && (
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              {question?.label}
            </Typography>
          )}
          <Paragraph>
            A <Highlight>{fieldSize.toLocaleString()}</Highlight>-person workforce that is{' '}
            <Highlight>{percentageUnproductiveWorkforce}%</Highlight> unproductive is a loss in labor costs of{' '}
            <Highlight>${weeklyLoss.toLocaleString()}</Highlight> per week. RIVET can save you a minimum{' '}
            <Highlight>{weeklyHoursSaved}</Highlight> hour per week for your unproductive workers. That's{' '}
            <Highlight>${annualSavings.toLocaleString()}</Highlight> in savings per year.
          </Paragraph>
        </Box>
      ) : (
        <Typography variant='body2'>
          <strong>{question?.label}</strong> requires values for{' '}
          <ul>
            {laborRate ? null : <li>Labor Rate</li>}
            {fieldSize ? null : <li>Field Size</li>}
            {percentageUnproductiveWorkforce ? null : <li>Percentage Of Unproductive Workforce</li>}
          </ul>
        </Typography>
      )}
    </>
  );
};
