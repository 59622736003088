import { selectEnabledQuestions } from '../../state';
import { useRecoilValue } from 'recoil';
import { Absences } from './Absences';
import { Box } from '@mui/material';
import { JobSiteCredentials } from './JobSiteCredentials';
import { PayForSelfWeeks } from './PayForSelfWeeks';
import { FieldCommunication } from './FieldCommunication';
import { Overtime } from './Overtime';
import { Spreadsheet } from './Spreadsheet';

export const Savings = () => {
  const enabledQuestions = useRecoilValue(selectEnabledQuestions);

  return (
    <Box sx={{ marginBottom: '24px' }}>
      {enabledQuestions.includes('Absences') && <Absences />}
      {enabledQuestions.includes('JobSiteCredentials') && <JobSiteCredentials />}
      {enabledQuestions.includes('FieldCommunication') && <FieldCommunication />}
      {enabledQuestions.includes('Overtime') && <Overtime />}
      {enabledQuestions.includes('Spreadsheet') && <Spreadsheet />}
      <PayForSelfWeeks />
    </Box>
  );
};
