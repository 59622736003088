import { Box, Typography } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useSetQuestion } from '../../hooks/useSetQuestion';
import {
  minutesPerWeekSkillsState,
  selectEnabledQuestions,
  selectFieldSize,
  selectLaborRate,
  selectQuestions,
} from '../../state';
import { Highlight } from './Highlight';
import { Paragraph } from './Paragraph';

export const JobSiteCredentials = () => {
  const setQuestion = useSetQuestion();

  const [annualSavings, setAnnualSavings] = useState(0);

  const enabledQuestions = useRecoilValue(selectEnabledQuestions);
  const questions = useRecoilValue(selectQuestions);
  const question = questions.find((q) => q.id === 'JobSiteCredentials');
  const laborRate = useRecoilValue(selectLaborRate);
  const fieldSize = useRecoilValue(selectFieldSize);
  const minutesPerWeekSkills = useRecoilValue(minutesPerWeekSkillsState);
  const jobSites = Math.ceil(fieldSize / 5);

  const isActive = question && enabledQuestions.includes('JobSiteCredentials');
  const isVisible = Boolean(laborRate && fieldSize && minutesPerWeekSkills);
  const showTitle = isActive && enabledQuestions.length > 1;
  const yearlySavedHours = (minutesPerWeekSkills / 60) * 50 * jobSites;

  useLayoutEffect(() => {
    const annualSavings = yearlySavedHours * laborRate;
    setAnnualSavings(annualSavings);
    if (question) {
      setQuestion({
        ...question,
        annualSavings: annualSavings,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearlySavedHours, laborRate]);

  return (
    <>
      {isVisible ? (
        <Box sx={{ marginBottom: '24px' }}>
          {showTitle && (
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              {question.label}
            </Typography>
          )}
          <Paragraph>
            If you have <Highlight>{jobSites.toLocaleString()}</Highlight> jobs this year and{' '}
            <Highlight>{fieldSize.toLocaleString()}</Highlight> workers, and you need to assign and manage worker
            qualifications for each job, RIVET's certification features can save you{' '}
            <Highlight>{yearlySavedHours.toLocaleString()}</Highlight> hours (or{' '}
            <Highlight>${Math.round(annualSavings).toLocaleString()}</Highlight>) this year. And that's just one
            feature.
          </Paragraph>
        </Box>
      ) : (
        <Typography variant='body2'>
          <strong>{question?.label}</strong> requires values for{' '}
          <ul>
            {laborRate ? null : <li>Labor Rate</li>}
            {fieldSize ? null : <li>Field Size</li>}
            {minutesPerWeekSkills ? null : <li>Minutes Per Week Per Job</li>}
          </ul>
        </Typography>
      )}
    </>
  );
};
