import { Box, Typography } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useSetQuestion } from '../../hooks/useSetQuestion';
import {
  selectAverageOvertimeLaborRate,
  selectEnabledQuestions,
  selectFieldSize,
  selectLaborRate,
  selectOvertimeWeeklyHours,
  selectOvertimeWorkersPerWeek,
  selectQuestions,
} from '../../state';
import { Highlight } from './Highlight';
import { Paragraph } from './Paragraph';

export const Overtime = () => {
  const setQuestion = useSetQuestion();

  const [annualSavings, setAnnualSavings] = useState(0);

  const enabledQuestions = useRecoilValue(selectEnabledQuestions);
  const questions = useRecoilValue(selectQuestions);
  const question = questions.find((q) => q.id === 'Overtime');
  const laborRate = useRecoilValue(selectLaborRate);
  const fieldSize = useRecoilValue(selectFieldSize);
  const averageOvertimeLaborRateMultiplier = useRecoilValue(selectAverageOvertimeLaborRate);
  const averageOvertimeLaborRate = averageOvertimeLaborRateMultiplier * laborRate;
  const overtimeWorkersPerWeekPercentage = useRecoilValue(selectOvertimeWorkersPerWeek);
  const overtimeWorkersPerWeek = Math.ceil(fieldSize * (overtimeWorkersPerWeekPercentage / 100));
  const overtimeWeeklyHours = useRecoilValue(selectOvertimeWeeklyHours);

  const isVisible = Boolean(fieldSize && averageOvertimeLaborRate && overtimeWorkersPerWeek && overtimeWeeklyHours);
  const showTitle = enabledQuestions.length > 1;
  const weeklyOverage = averageOvertimeLaborRate * overtimeWorkersPerWeek * overtimeWeeklyHours;

  useLayoutEffect(() => {
    const annualSavings = weeklyOverage * 0.25 * 50;
    setAnnualSavings(annualSavings);
    if (question) {
      setQuestion({
        ...question,
        annualSavings: annualSavings,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklyOverage]);

  return (
    <>
      {isVisible ? (
        <Box sx={{ marginBottom: '24px' }}>
          {showTitle && (
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              {question?.label}
            </Typography>
          )}
          <Paragraph>
            If you’re paying <Highlight>{overtimeWeeklyHours.toLocaleString()}</Highlight> hours of overtime at a rate
            of <Highlight>${averageOvertimeLaborRate.toLocaleString()}/hr</Highlight> for{' '}
            <Highlight>{overtimeWorkersPerWeek.toLocaleString()}</Highlight> workers per week, that’s a{' '}
            <Highlight>${weeklyOverage.toLocaleString()}</Highlight> overage. RIVET can help you reduce your overtime by{' '}
            <Highlight>25%</Highlight>. That’s an annual savings of{' '}
            <Highlight>${Math.round(annualSavings).toLocaleString()}</Highlight>.
          </Paragraph>
        </Box>
      ) : (
        <Typography variant='body2'>
          <strong>{question?.label}</strong> requires values for{' '}
          <ul>
            {laborRate ? null : <li>Labor Rate</li>}
            {fieldSize ? null : <li>Field Size</li>}
            {averageOvertimeLaborRate ? null : <li>Average Overtime Labor Rate</li>}
            {overtimeWorkersPerWeek ? null : <li>Weekly Overtime Workers</li>}
            {overtimeWeeklyHours ? null : <li>Overtime Paid Per Week</li>}
          </ul>
        </Typography>
      )}
    </>
  );
};
