import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { selectEnabledQuestions } from '../state';

export const Instructions = () => {
  const enabledQuestions = useRecoilValue(selectEnabledQuestions);
  const isVisible = !enabledQuestions?.length || enabledQuestions.length === 0;

  return <>{isVisible ? <Typography>Select the problems that apply to you to display savings.</Typography> : null}</>;
};
