import { Box, useMediaQuery } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { selectMonthlySavings, selectAnnualSavings } from '../../state';
import { SavingsAggregate } from './SavingsAggregate';

export const TotalSavings = () => {
  const monthlySavings = useRecoilValue(selectMonthlySavings);
  const annualSavings = useRecoilValue(selectAnnualSavings);
  const isVisible = Boolean(monthlySavings + annualSavings > 0);
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      {isVisible && (
        <Box display={'flex'} sx={{ flexDirection: isMobile ? 'column' : 'row' }} justifyContent='space-evenly'>
          <SavingsAggregate value={monthlySavings} label={'Savings per month with RIVET'} />
          <SavingsAggregate value={annualSavings} label={'Savings per year with RIVET'} />
        </Box>
      )}
    </>
  );
};
