import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import Constants from '../../constants';
import { selectAnnualSavings, selectEnabledQuestions, selectFieldSize } from '../../state';
import { Highlight } from './Highlight';
import { Paragraph } from './Paragraph';

export const PayForSelfWeeks = () => {
  const enabledQuestions = useRecoilValue(selectEnabledQuestions);
  const isPlural = enabledQuestions.length > 1;
  const fieldSize = useRecoilValue(selectFieldSize);
  const rivetCostPerYear = Constants.cost.perFieldWorker * fieldSize * 12;
  const annualSavings = useRecoilValue(selectAnnualSavings);
  const payForSelfWeeks = Math.ceil((rivetCostPerYear / annualSavings) * 52);
  const roi = annualSavings / rivetCostPerYear;
  const isVisible = Boolean(fieldSize && annualSavings);

  return (
    <>
      {isVisible ? (
        <Box sx={{ marginBottom: '24px' }}>
          <Paragraph>
            {isPlural ? 'At these combined rates, ' : 'At this rate, '} RIVET's entire annual subscription would pay for
            itself in <Highlight>{payForSelfWeeks}</Highlight> weeks. That's an ROI of{' '}
            <Highlight>{roi.toFixed(1)}x</Highlight>.
          </Paragraph>
        </Box>
      ) : null}
    </>
  );
};
