import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import {
  averageOvertimeLaborRateState,
  fieldSizeState,
  minutesPerWeekSkillsState,
  inputSelector,
  laborRateState,
  overtimeWeeklyHoursPaid,
  overtimeWorkersPerWeekState,
  percentageUnproductiveWorkforceState,
} from '../../state';
import { ValueInput } from './ValueInput';

export const Inputs = () => {
  const inputs = useRecoilValue(inputSelector);

  return (
    <Box display='block'>
      {inputs.includes('laborRateState') && (
        <ValueInput
          state={laborRateState}
          placeholder='Labor Rate'
          helper='What is your average hourly labor rate?'
          startAdornment='$'
          presets={[
            {
              value: 50,
              startAdornment: '$',
            },
            {
              value: 75,
              startAdornment: '$',
            },
            {
              value: 100,
              startAdornment: '$',
            },
          ]}
        />
      )}
      {inputs.includes('fieldSizeState') && (
        <ValueInput
          state={fieldSizeState}
          placeholder='Field Size'
          helper='What is your field size?'
          presets={[
            {
              value: 50,
            },
            {
              value: 100,
            },
            {
              value: 250,
            },
            {
              value: 500,
            },
            {
              value: 1000,
            },
          ]}
        />
      )}
      {inputs.includes('minutesPerWeekSkillsState') && (
        <ValueInput
          state={minutesPerWeekSkillsState}
          placeholder='Minutes Per Week Per Job'
          helper='How many minutes per job per week do you spend finding workers with the the right skills, certifications, trainings or credentials?'
          presets={[
            {
              value: 15,
              endAdornment: ' minutes',
            },
            {
              value: 30,
              endAdornment: ' minutes',
            },
            {
              value: 60,
              endAdornment: ' minutes',
            },
          ]}
        />
      )}
      {inputs.includes('percentageUnproductiveWorkforceState') && (
        <ValueInput
          state={percentageUnproductiveWorkforceState}
          placeholder='Percentage Of Unproductive Workforce'
          helper='What percentage of your workforce do you estimate is unproductive? Examples: sitting, unclear work instructions, unclear field changes.'
          endAdornment='%'
          presets={[
            {
              value: 5,
              endAdornment: '%',
            },
            {
              value: 10,
              endAdornment: '%',
            },
            {
              value: 15,
              endAdornment: '%',
            },
          ]}
        />
      )}
      {inputs.includes('averageOvertimeLaborRateState') && (
        <ValueInput
          state={averageOvertimeLaborRateState}
          placeholder='Average Overtime Labor Rate'
          helper='What is your average overtime labor rate multiplier?'
          endAdornment='x'
          presets={[
            {
              value: 1.5,
              endAdornment: 'x',
            },
            {
              value: 1.75,
              endAdornment: 'x',
            },
            {
              value: 2,
              endAdornment: 'x',
            },
          ]}
        />
      )}
      {inputs.includes('overtimeWorkersPerWeekState') && (
        <ValueInput
          state={overtimeWorkersPerWeekState}
          placeholder='Weekly Overtime Percentage'
          helper='What percentage of your workforce is paid overtime weekly?'
          endAdornment='%'
          presets={[
            {
              value: 2.5,
              endAdornment: '%',
            },
            {
              value: 5,
              endAdornment: '%',
            },
            {
              value: 10,
              endAdornment: '%',
            },
          ]}
        />
      )}
      {inputs.includes('overtimeWeeklyHoursPaid') && (
        <ValueInput
          state={overtimeWeeklyHoursPaid}
          placeholder='Overtime Paid Per Week'
          helper='How many hours per week of overtime is typically paid per worker?'
          presets={[
            {
              value: 5,
              endAdornment: ' hours',
            },
            {
              value: 10,
              endAdornment: ' hours',
            },
            {
              value: 15,
              endAdornment: ' hours',
            },
          ]}
        />
      )}
    </Box>
  );
};
