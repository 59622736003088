import { Box } from '@mui/material';
import Constants from '../../constants';

interface Props {
  children: React.ReactNode;
}

export const Highlight = ({ children }: Props) => {
  return (
    <Box component='span' sx={{ color: Constants.hue.orange[500] }}>
      {children}
    </Box>
  );
};
