import { Box, Button, FormControl, FormHelperText, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, RecoilState } from 'recoil';

interface Preset {
  startAdornment?: string;
  endAdornment?: string;
  value: number;
}

interface Props {
  state: RecoilState<number>;
  placeholder: string;
  helper: string;
  startAdornment?: string;
  endAdornment?: string;
  presets?: Preset[];
}

export const ValueInput = ({ state, placeholder, helper, startAdornment, endAdornment, presets }: Props) => {
  const [value, setValue] = useRecoilState(state);
  const [localValue, setLocalValue] = useState(value === 0 ? '' : value.toString());

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalValue(event.currentTarget.value);
  };

  useEffect(() => {
    const val = parseFloat(localValue);
    if (val >= 0) {
      setValue(val);
    } else {
      setValue(0);
    }
  }, [localValue, setValue]);

  const handleClick = (value: string) => {
    setLocalValue(value);
  };

  return (
    <FormControl fullWidth sx={{ margin: '16px 0' }}>
      <FormHelperText sx={{ marginBottom: '4px', marginLeft: '4px' }}>{helper}</FormHelperText>
      <OutlinedInput
        placeholder={placeholder}
        type='number'
        value={localValue}
        onChange={handleChange}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
      />
      {presets ? (
        <Box display='flex' sx={{ marginLeft: '4px', marginTop: '8px', overflowY: 'auto' }}>
          <>
            {presets.map((preset) => (
              <Button
                onClick={() => handleClick(preset.value.toString())}
                variant='text'
                size='small'
                color='secondary'
                sx={{
                  marginRight: '8px',
                  textTransform: 'none',
                  height: '24px',
                  backgroundColor: 'rgba(240,82,55,0.1)',
                }}
                key={preset.value}
              >
                {preset.startAdornment ? preset.startAdornment : null}
                {preset.value.toString()}
                {preset.endAdornment ? preset.endAdornment : null}
              </Button>
            ))}
          </>
        </Box>
      ) : null}
    </FormControl>
  );
};
