import { Box, Typography } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useSetQuestion } from '../../hooks/useSetQuestion';
import { selectEnabledQuestions, selectFieldSize, selectLaborRate, selectQuestions } from '../../state';
import { Highlight } from './Highlight';
import { Paragraph } from './Paragraph';

export const Spreadsheet = () => {
  const setQuestion = useSetQuestion();

  const [annualSavings, setAnnualSavings] = useState(0);

  const enabledQuestions = useRecoilValue(selectEnabledQuestions);
  const questions = useRecoilValue(selectQuestions);
  const question = questions.find((q) => q.id === 'Spreadsheet');
  const laborRate = useRecoilValue(selectLaborRate);
  const fieldSize = useRecoilValue(selectFieldSize);

  const isVisible = Boolean(laborRate && fieldSize);
  const showTitle = enabledQuestions.length > 1;
  const sittingDayWorkers = Math.ceil(fieldSize * 0.02);
  const sittingDayCost = sittingDayWorkers * laborRate * 8;

  useLayoutEffect(() => {
    const annualSavings = fieldSize ? sittingDayCost * 24 : 0;
    setAnnualSavings(annualSavings);
    if (question) {
      setQuestion({
        ...question,
        annualSavings: annualSavings,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sittingDayCost, fieldSize]);

  return (
    <>
      {isVisible ? (
        <Box sx={{ marginBottom: '24px' }}>
          {showTitle && (
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              {question?.label}
            </Typography>
          )}
          <Paragraph>
            If your jobs are ending or delayed, and you haven't transferred new assignments while you sort out your
            spreadsheet, your workers are sitting and you're leaking labor costs at the job site. If you're paying{' '}
            <Highlight>{sittingDayWorkers}</Highlight> workers to sit for while you catch up on swapping and announcing
            changes, that's <Highlight>${sittingDayCost.toLocaleString()}</Highlight> per day of leaking costs. If RIVET
            is able to save you 2 days per month of sitting assignments, that's{' '}
            <Highlight>${annualSavings.toLocaleString()}</Highlight> per year in savings.
          </Paragraph>
        </Box>
      ) : (
        <Typography variant='body2'>
          <strong>{question?.label}</strong> requires values for{' '}
          <ul>
            {laborRate ? null : <li>Labor Rate</li>}
            {fieldSize ? null : <li>Field Size</li>}
          </ul>
        </Typography>
      )}
    </>
  );
};
