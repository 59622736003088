import { Box, Typography } from '@mui/material';
import Constants from '../../constants';

interface Props {
  value: number;
  label: string;
}

export const SavingsAggregate = ({ value, label }: Props) => {
  return (
    <Box sx={{ textAlign: 'center', margin: '16px' }}>
      <Typography
        variant='h2'
        sx={{
          fontWeight: 700,
          fontSize: '50px',
          lineHeight: '64px',
          color: Constants.hue.blue[500],
          '@media (max-width: 960px)': {
            fontSize: '40px',
          },
          '@media (max-width: 720px)': {
            fontSize: '32px',
          },
          '@media (max-width: 600px)': {
            fontSize: '60px',
          },
        }}
      >
        ${Math.round(value).toLocaleString()}
      </Typography>
      <Box sx={{ maxWidth: '160px', margin: '0 auto' }}>
        <Typography variant='body1' sx={{ fontWeight: 700 }}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};
