export default class Constants {
  static cost = {
    perFieldWorker: 8,
  };
  static hue = {
    blue: {
      50: '#E4E8EE',
      100: '#BBC7D6',
      300: '#657F9E',
      400: '#44658C',
      500: '#1A4C7C',
      700: '#063C69',
      800: '#01335D',
      900: '#002345', // unionBlue
      A300: '#55AAF0',
    },
    orange: {
      500: '#F05237', // primary orange
      600: '#D84A32',
      700: '#C0422C',
      800: '#A83927', // hover orange
      900: '#903121',
    },
    yellow: {
      500: '#FF9800',
    },
    error: '#F44336',
    success: '#4CAF50',
  };

  static theme = {
    text: {
      primary: '#333940',
      secondary: '#627183',
    },
  };
}
