import { useRecoilState } from 'recoil';
import { Question, questionsState } from '../state';
import { getQuestionIndex, replaceItemAtIndex } from '../utils';

export const useSetQuestion = () => {
  const [questions, setQuestions] = useRecoilState(questionsState);

  return (question: Question) => {
    const index = getQuestionIndex(questions, question?.id ?? '');
    if (index > -1 && question) {
      setQuestions((questions) => replaceItemAtIndex<Question>(questions, index, question));
    }
  };
};
