import { Box, Grid, Paper, Typography } from '@mui/material';
import { Inputs } from './components/Inputs';
import { Instructions } from './components/Instructions';
import { Questions } from './components/Questions';
import { Savings } from './components/Savings';
import { TotalSavings } from './components/TotalSavings';
import Constants from './constants';

function App() {
  return (
    <Box sx={{ maxWidth: '1024px', margin: '0 auto' }}>
      <Grid container>
        <Grid item xs={12} sm={6} padding={0}>
          <Paper elevation={3} sx={{ padding: '16px' }}>
            <Typography variant='h4' sx={{ color: Constants.hue.blue['A300'], marginBottom: '24px' }}>
              Savings Calculator
            </Typography>
            <Typography variant='body1' color={Constants.theme.text.secondary}>
              Which of these problems apply to you?
            </Typography>
            <Questions />
            <Inputs />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} padding={2}>
          <Typography variant='h3' sx={{ marginBottom: '16px' }}>
            Savings Summary
          </Typography>
          <Instructions />
          <Savings />
          <TotalSavings />
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
