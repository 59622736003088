import { atom, selector } from 'recoil';

export type QuestionInput =
  | 'laborRateState'
  | 'fieldSizeState'
  | 'minutesPerWeekSkillsState'
  | 'percentageUnproductiveWorkforceState'
  | 'averageOvertimeLaborRateState'
  | 'overtimeWorkersPerWeekState'
  | 'overtimeWeeklyHoursPaid';

export const laborRateState = atom<number>({
  key: 'laborRateState',
  default: 0,
});

export const fieldSizeState = atom<number>({
  key: 'fieldSizeState',
  default: 0,
});

export const minutesPerWeekSkillsState = atom<number>({
  key: 'minutesPerWeekSkillsState',
  default: 0,
});

export const percentageUnproductiveWorkforceState = atom<number>({
  key: 'percentageUnproductiveWorkforceState',
  default: 0,
});

export const averageOvertimeLaborRateState = atom<number>({
  key: 'averageOvertimeLaborRateState',
  default: 0,
});

export const overtimeWorkersPerWeekState = atom<number>({
  key: 'overtimeWorkersPerWeekState',
  default: 0,
});

export const overtimeWeeklyHoursPaid = atom<number>({
  key: 'overtimeWeeklyHoursPaid',
  default: 0,
});

export interface Question {
  id: 'Absences' | 'JobSiteCredentials' | 'FieldCommunication' | 'Overtime' | 'Spreadsheet';
  label: string;
  enabled: boolean;
  annualSavings: number;
  inputs: QuestionInput[];
}

export const questionsState = atom<Question[]>({
  key: 'questionsState',
  default: [
    {
      id: 'Absences',
      label: 'Absences and No Shows',
      enabled: false,
      annualSavings: 0,
      inputs: ['fieldSizeState', 'laborRateState'],
    },
    {
      id: 'JobSiteCredentials',
      label: 'Job Site Credentials',
      enabled: false,
      annualSavings: 0,
      inputs: ['fieldSizeState', 'minutesPerWeekSkillsState', 'laborRateState'],
    },
    {
      id: 'FieldCommunication',
      label: 'Field Communication',
      enabled: false,
      annualSavings: 0,
      inputs: ['fieldSizeState', 'laborRateState', 'percentageUnproductiveWorkforceState'],
    },
    {
      id: 'Overtime',
      label: 'Overtime, Over- and Under-Staffing',
      enabled: false,
      annualSavings: 0,
      inputs: [
        'fieldSizeState',
        'laborRateState',
        'averageOvertimeLaborRateState',
        'overtimeWorkersPerWeekState',
        'overtimeWeeklyHoursPaid',
      ],
    },
    {
      id: 'Spreadsheet',
      label: 'Labor Spreadsheets',
      enabled: false,
      annualSavings: 0,
      inputs: ['fieldSizeState', 'laborRateState'],
    },
  ],
});

export const inputSelector = selector({
  key: 'inputSelector',
  get: ({ get }) => {
    const questions = get(questionsState);
    return Array.from(new Set(questions.filter(({ enabled }) => enabled).flatMap(({ inputs }) => inputs)));
  },
});

export const selectEnabledQuestions = selector({
  key: 'enabledQuestionsSelector',
  get: ({ get }) => {
    const questions = get(questionsState);
    return questions.filter(({ enabled }) => enabled).map(({ id }) => id);
  },
});

export const selectQuestions = selector({
  key: 'selectQuestions',
  get: ({ get }) => {
    return get(questionsState);
  },
});

export const selectQuestionById = (id: string) =>
  selector({
    key: 'selectQuestionById',
    get: ({ get }) => {
      const questions = get(questionsState);
      return questions.find((q) => q.id === id);
    },
  });

export const selectLaborRate = selector({
  key: 'selectLaborRate',
  get: ({ get }) => {
    return get(laborRateState);
  },
});

export const selectFieldSize = selector({
  key: 'selectFieldSize',
  get: ({ get }) => {
    return get(fieldSizeState);
  },
});

export const selectPercentageUnproductiveWorkforce = selector({
  key: 'selectPercentageUnproductiveWorkforce',
  get: ({ get }) => {
    return get(percentageUnproductiveWorkforceState);
  },
});

export const selectAverageOvertimeLaborRate = selector({
  key: 'selectAverageOvertimeLaborRate',
  get: ({ get }) => {
    return get(averageOvertimeLaborRateState);
  },
});

export const selectOvertimeWorkersPerWeek = selector({
  key: 'selectOvertimeWorkersPerWeek',
  get: ({ get }) => {
    return get(overtimeWorkersPerWeekState);
  },
});

export const selectOvertimeWeeklyHours = selector({
  key: 'selectOvertimeWeeklyHours',
  get: ({ get }) => {
    return get(overtimeWeeklyHoursPaid);
  },
});

export const selectAnnualSavings = selector({
  key: 'selectMonthlySavings',
  get: ({ get }) => {
    const questions = get(questionsState);
    return questions
      .filter(({ enabled }) => enabled)
      .map(({ annualSavings }) => annualSavings)
      .reduce((sum, savings) => sum + savings, 0);
  },
});

export const selectMonthlySavings = selector({
  key: 'selectYearlySavings',
  get: ({ get }) => {
    const monthlySavings = get(selectAnnualSavings);
    return Math.round(monthlySavings / 12);
  },
});
