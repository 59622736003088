import { Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export const Paragraph = ({ children }: Props) => {
  return (
    <Typography variant='body1' sx={{ marginBottom: '8px' }}>
      {children}
    </Typography>
  );
};
