import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useSetQuestion } from '../hooks/useSetQuestion';
import { Question, selectQuestions } from '../state';

export const Questions = () => {
  const questions = useRecoilValue(selectQuestions);
  const setQuestion = useSetQuestion();

  const handleChange = (question: Question) => {
    setQuestion({
      ...question,
      enabled: !question.enabled,
    });
  };

  return (
    <FormGroup>
      {questions.map((question) => (
        <FormControlLabel
          key={question.label}
          control={<Checkbox checked={question.enabled} onChange={() => handleChange(question)} />}
          label={question.label}
        />
      ))}
    </FormGroup>
  );
};
